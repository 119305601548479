import React from 'react';
import ConfigComponent from './configComponent';
import { HomeWrapper } from './styles';
import WebSocketProvider from './WS';
import { Provider } from 'react-redux';
import store from './store';

const App = () => (
  <Provider store={store}>
    <WebSocketProvider>
      <div className="App">
        <header className="App-header"></header>
        <HomeWrapper>
          <ConfigComponent />
        </HomeWrapper>
      </div>
    </WebSocketProvider>
  </Provider>
);

export default App;
