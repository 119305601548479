import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import WSEvents from '../enums/WSEnum';

const initialDataPayload = { currentConfig: 0, isLoading: true, configs: [{ id: '', rest_token: '', install_type: '', install_location: '' }] };

export const updateConfigSlice = createSlice({
  name: WSEvents.UPDATE_CONFIG,
  initialState: initialDataPayload,
  reducers: {
    update: (state, { payload }) => {
      if (state === payload) {
        return;
      }
      return payload;
    },
    create: (state, { payload }) => {
      let configsAmount = payload.configs.length;
      let configs = state.configs;
      let newConfigs = [...configs, { ...initialDataPayload.configs[0], id: uuidv4(), rest_token: 'someToken' }];
      // console.log(current(configs));
      const newPayload = { currentConfig: configsAmount, isLoading: false, configs: newConfigs };

      if (state === payload) {
        return;
      }
      return (state = newPayload);
    },
  },
});

export const { update, create } = updateConfigSlice.actions;

export default updateConfigSlice.reducer;
