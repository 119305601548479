import React, { useContext, ChangeEvent } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { update, create } from '../configComponent/updateConfigSlice';
import ContentLoader from 'react-content-loader';
import { WebSocketContext } from '../WS';
import { ConfigWrapper, FormWrapper, ChoiceContainer, PCChoice, SlashCharacter, QuestChoice, SaveButton, PathTextField } from './styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import SaveIcon from '@material-ui/icons/Save';

const MyLoader = () => (
  <ContentLoader height={80} speed={1} backgroundColor={'#333'} foregroundColor={'#999'} viewBox="0 0 472 70">
    {/* Only SVG shapes */}
    <rect x="25" y="10" rx="0" ry="0" width="450" height="70" />
  </ContentLoader>
);

const ConfigComponent = () => {
  const dispatch = useAppDispatch();
  const dataPayload = useAppSelector((state) => {
    return state.updateConfig;
  });
  const currentConfigIndex = useAppSelector((state) => {
    return state.updateConfig.currentConfig;
  });
  const isLoading = useAppSelector((state) => {
    return state.updateConfig.isLoading;
  });
  const configs = useAppSelector((state) => {
    return state.updateConfig.configs;
  });
  const currentConfig = useAppSelector((state) => {
    return state.updateConfig.configs[currentConfigIndex];
  });

  const type = currentConfig.install_type;
  const location = currentConfig.install_location;

  const ws = useContext(WebSocketContext);

  function updateConfig() {
    ws.sendConfig(configs);
  }

  const getNewPayload = (valueType: string, value: any) => {
    const newCurrentConfig = { ...currentConfig, [valueType]: value };
    const newConfigs = configs.map((config: {}, index) => {
      index === currentConfigIndex && (config = newCurrentConfig);
      return config;
    });
    return { currentConfig: currentConfigIndex, configs: newConfigs };
  };

  const handleCreateConfig = () => dispatch(create({ configs }));
  const handleConfigChange = (event: ChangeEvent<{ value: unknown }>) => dispatch(update({ currentConfig: event.target.value, configs }));
  const handleTypeChange = (type: string) => dispatch(update(getNewPayload('install_type', type)));
  const handleLocationChange = (event: ChangeEvent<{ value: unknown }>) => {
    return dispatch(update(getNewPayload('install_location', event.target.value)));
  };

  const handleClick = () => {
    updateConfig();
  };

  // const ref = React.useRef<HTMLInputElement>(null);

  // React.useEffect(() => {
  //   if (ref.current !== null) {
  //     ref.current.setAttribute('directory', '');
  //     ref.current.setAttribute('webkitdirectory', '');
  //   }
  // }, [ref]);
  let isTypeLoading = isLoading ? 'loading...' : 'please select a value';
  return (
    <ConfigWrapper>
      <InputLabel id="config-select-label">Configuration</InputLabel>
      <button onClick={() => handleCreateConfig()}>create config</button>
      <Select labelId="config-select-label" id="config-select" value={currentConfigIndex} onChange={handleConfigChange}>
        {configs.map((config, index) => (
          <MenuItem value={index} key={'configItem' + index}>
            {index}
          </MenuItem>
        ))}
      </Select>
      <FormWrapper>
        <ChoiceContainer>
          <PCChoice value={type} isActive={type === 'PC'} onClick={() => handleTypeChange('PC')}>
            PC
          </PCChoice>
          <SlashCharacter>/</SlashCharacter>
          <QuestChoice isActive={type === 'Quest'} onClick={() => handleTypeChange('Quest')}>
            Quest
          </QuestChoice>
        </ChoiceContainer>
        <FormHelperText>{`Installation Type (your current one is ${type ? type : isTypeLoading})`}</FormHelperText>
        {/* <LocationInput required id="standard-required" label="Required" type="file" ref={ref} onChange={handleLocationChange} />
        <input id="standard-required" ref={ref} type="file" onChange={handleLocationChange} /> */}
        {isLoading ? (
          <MyLoader />
        ) : (
          <PathTextField
            required
            id="standard-required"
            label="Required"
            helperText="BeatSaber folder location"
            value={location}
            onChange={handleLocationChange}
            key={'textfield'}
          />
        )}
        <SaveButton onClick={() => handleClick()} variant="outlined" color="primary" startIcon={<SaveIcon />}>
          Save
        </SaveButton>
      </FormWrapper>
    </ConfigWrapper>
  );
};
export default ConfigComponent;
