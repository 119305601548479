import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const ConfigWrapper = styled.div``;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  align-items: center;
  align-content: center;
`;
export const SelectTypeWrapper = styled.div`
  margin: 2.4rem 0;
`;
export const ChoiceContainer = styled.div`
  margin: 1rem 0;
  background: linear-gradient(45deg, #de0506, #0195ec);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PCChoice = styled.button<{ isActive: boolean }>`
  font-size: 3.2rem;
  background: none;
  border: none;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    background-color: #e40304;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  `}
`;
export const SlashCharacter = styled.button`
  font-size: 3.2rem;
  background: none;
  border: none;
  cursor: pointer;
`;

export const QuestChoice = styled.button<{ isActive: boolean }>`
  font-size: 3.2rem;
  background: none;
  border: none;
  cursor: pointer;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    background-color: #018de3;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  `}
`;

export const PathTextField = withStyles({
  root: {
    width: '75ch',
    margin: '2.4rem 0',
  },
})(TextField);

export const SaveButton = withStyles({
  root: {
    width: '8rem',
    margin: '0.8rem 0',
  },
})(Button);
