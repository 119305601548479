import React, { createContext } from 'react';
import config from './config/config.json';
import WSEvents from './enums/WSEnum';
import { useAppDispatch } from './hooks';
import { update } from './configComponent/updateConfigSlice';

type Socket = WebSocket | undefined;
type WebSocketObject = { socket: Socket; sendConfig: (payload: Data) => void };
type Config = { id: string; rest_token: string; install_type: string; install_location: string };
type Data = { [index: number]: Config };

let socket: Socket = undefined;
let websocket: WebSocketObject = {
  socket,
  sendConfig: () => {},
};

const WebSocketContext = createContext(websocket);

export { WebSocketContext };

const WS = ({ children }: any) => {
  let connectInterval: ReturnType<typeof setTimeout>;
  let timeout: number = 250;
  const dispatch = useAppDispatch();
  const check = () => {
    if (!socket || socket.readyState === WebSocket.CLOSED) {
      socket = new WebSocket(config.WS_SERVER_URL);
    } //check if websocket instance is closed, if so call `connect` function.
  };

  const sendConfig = (data: Data) => {
    const payload = {
      type: WSEvents.UPDATE_CONFIG,
      data: data,
    };
    try {
      return socket?.send(JSON.stringify(payload)); //send data to ws the server
    } catch (error) {
      console.log(error);
    }
    return;
  };

  if (!socket) {
    socket = new WebSocket(config.WS_SERVER_URL);

    socket.onopen = () => {
      console.info('connected websocket main component');

      timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    socket.onerror = (err) => {
      console.error('Socket encountered error: ', err, 'Closing socket');

      socket?.close();
    };

    socket.onclose = (event) => {
      console.warn(`Socket is closed. Reconnect will be attempted in ${Math.min(10000 / 1000, (timeout + timeout) / 1000)} second.`, event.reason);

      timeout = timeout + timeout; //increment retry interval
      return (connectInterval = setTimeout(check, Math.min(10000, timeout))); //call check function after timeout
    };

    socket.onmessage = (evt) => {
      // listen to data sent from the websocket server
      const socketData = JSON.parse(evt.data);

      const configs = socketData.data.map((config: Config) => {
        return config;
      });

      return dispatch(update({ currentConfig: 0, isLoading: false, configs }));
    };

    websocket = {
      socket: socket,
      sendConfig,
    };
  }

  return <WebSocketContext.Provider value={websocket}>{children}</WebSocketContext.Provider>;
};
export default WS;
